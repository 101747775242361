
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















.subpage__flexible {
  ::v-deep > div:first-child {
    margin-top: 0;
  }
}
